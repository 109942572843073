.extensions {
  margin: 0 0 45px;
  @include media($w1175) {
    margin: 0 0 40px;
  }
  @include media($w1024) {
    margin: 0 0 30px;
  }
  h3 {
    margin: 0 0 40px;
    @include media($w1024) {
      margin: 0 0 30px;
    }
  }
  &__list {
    margin: 0 0 -16px;
    li {
      width: calc((100% / 2) - 8px);
      margin: 0 16px 16px 0;
      @include media($w768) {
        width: 100%;
        margin: 0 0 12px;
      }
      @include media($w560) {
        margin: 0 0 8px;
      }
      &:nth-child(2n+2) {
        margin: 0 0 16px;
        @include media($w768) {
          margin: 0 0 12px;
        }
        @include media($w560) {
          margin: 0 0 8px;
        }
      }
      a {
        border-radius: 56px;
        background-color: $gray-03;
        width: 100%;
        height: 56px;
        font-size: 18px;
        line-height: 56px;
        color: $blue-02;
        padding: 0 0 0 32px;
        float: left;
        @include media($w1175) {
          height: 50px;
          font-size: 16px;
          line-height: 50px;
          padding: 0 0 0 30px;
        }
        @include media($w1024) {
          height: 44px;
          line-height: 44px;
          padding: 0 0 0 25px;
        }
        @include media($w560) {
          height: 40px;
          font-size: 14px;
          line-height: 40px;
          padding: 0 0 0 20px;
        }
        span {
          @include transition;
          width: 100px;
          color: $blue-03;
          float: left;
          @include media($w1175) {
            width: 80px;
          }
          @include media($w1024) {
            width: 55px;
          }
        }
        &:hover {
          span {
            color: $blue-02;
          }
        }
      }
    }
  }
}
