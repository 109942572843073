.browse {
  margin: 0 0 50px;
  h3 {
    margin: 0 0 40px;
    @include media($w1024) {
      margin: 0 0 35px;
    }
  }
  &__list {
    margin: 0 0 -16px;
    li {
      width: calc((100% / 4) - 12px);
      margin: 0 16px 16px 0;
      @include media($w768) {
        width: auto;
        margin: 0 12px 12px 0;
      }
      @include media($w560) {
        margin: 0 8px 8px 0;
      }
      &:nth-child(4n+4) {
        margin: 0 0 16px;
        @include media($w768) {
          margin: 0 12px 12px 0;
        }
        @include media($w560) {
          margin: 0 8px 8px 0;
        }
      }
      a {
        border-radius: 56px;
        background-color: $gray-03;
        width: 100%;
        height: 56px;
        font-size: 18px;
        line-height: 56px;
        text-align: center;
        color: $blue-03;
        float: left;
        @include media($w1024) {
          height: 50px;
          font-size: 16px;
          line-height: 50px;
        }
        @include media($w768) {
          height: 44px;
          line-height: 44px;
          padding: 0 20px;
        }
        @include media($w768) {
          height: 36px;
          font-size: 14px;
          line-height: 36px;
        }
        &:hover {
          color: $blue-02;
        }
      }
    }
  }
  &--similar {
    .browse__list {
      li {
        width: auto;
        margin: 0 16px 16px 0;
        &:nth-child(4n+4) {
          margin: 0 16px 16px 0;
        }
        a {
          padding: 0 32px;
        }
      }
    }
  }
}
