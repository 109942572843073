.conversion {
  border: 1px solid darken($gray-03,8%);
  border-radius: 11px;
  margin: 0 0 40px;
  @include media($w1024) {
		margin: 0 0 30px;
	}
  &__item {
    width: 50%;
    height: 135px;
    padding: 0 0 0 30px;
    float: left;
    @include media($w1024) {
      height: 125px;
      padding: 0 0 0 25px;
    }
    @include media($w768) {
      height: 110px;
      padding: 0 0 0 20px;
    }
    @include media($w560) {
      width: 100%;
      height: 90px;
    }
    &--input {
      border-right: 1px solid darken($gray-03,8%);
      @include media($w560) {
        border: 0;
        border-bottom: 1px solid darken($gray-03,8%);
      }
    }
    .icon {
      @include translateY;
      border-radius: 50%;
      background-color: $gray-03;
      width: 64px;
      height: 64px;
      margin: 1px 0 0;
      float: left;
      @include media($w1024) {
        width: 50px;
        height: 50px;
      }
      img {
        @include translate;
        width: 24px;
        @include media($w1024) {
      		width: 20px;
      	}
      }
    }
    .text {
      @include translateY;
      margin: 4px 0 0 30px;
      float: left;
      @include media($w1024) {
        margin: 4px 0 0 20px;
      }
      @include media($w768) {
        margin: 3px 0 0 20px;
      }
      label {
        font-size: 20px;
        cursor: text;
        margin: 0 0 5px;
        display: block;
        @include media($w1024) {
      		font-size: 16px;
          margin: 0;
      	}
        @include media($w560) {
      		font-size: 14px;
      	}
      }
      .format {
        font-size: 40px;
        font-weight: 700;
        color: $blue-03;
        @include media($w1024) {
      		font-size: 36px;
      	}
        @include media($w768) {
      		font-size: 30px;
      	}
        @include media($w560) {
      		font-size: 24px;
      	}
      }
    }
  }
}
