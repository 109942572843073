.about {
  border-bottom: 1px solid darken($gray-03,5%);
  margin: 0 0 50px;
  padding: 0 0 35px;
  @include media($w1175) {
    margin: 0 0 40px;
  }
  @include media($w1024) {
    margin: 0 0 30px;
    padding: 0 0 30px;
  }
  h3 {
    margin: 0 0 45px;
    @include media($w1024) {
  		margin: 0 0 32px;
  	}
  }
  &__image {
    margin: 0 0 30px;
    img {
      border-radius: 15px;
      width: 100%;
    }
  }
  &__list {
    li {
      & + li {
        margin: 35px 0 0;
        @include media($w1024) {
      		margin: 25px 0 0;
      	}
        @include media($w560) {
          margin: 20px 0 0;
        }
      }
      .icon {
        width: 50px;
        margin: 5px 0 0;
        float: left;
        @include media($w560) {
          width: 40px;
        }
        img {
          width: 100%;
        }
      }
      .text {
        width: calc(100% - 82px);
        float: right;
        @include media($w1024) {
          width: calc(100% - 80px);
        }
        @include media($w560) {
          width: calc(100% - 60px);
        }
        h5 {
          margin: 0 0 15px;
          @include media($w1024) {
        		margin: 0 0 10px;
        	}
        }
      }
    }
  }
}
