.outer {
  position: relative;
  z-index: 50;
  &__inner {
    border-radius: 20px;
    background-color: $white;
    padding: 32px;
    @include media($w1024) {
      padding: 30px;
    }
    @include media($w768) {
      padding: 25px;
    }
    @include media($w560) {
      padding: 20px 25px 25px;
    }
    .mixed {
      &__main {
        width: calc(100% - 332px);
        float: left;
        @include media($w1024) {
          width: calc(100% - 300px);
        }
        @include media($w900) {
          width: 100%;
        }
        .page {
          & > * {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      &__sidebar {
        width: 300px;
        float: right;
        @include media($w1024) {
          width: 270px;
        }
        @include media($w900) {
          width: 100%;
          margin: 30px 0 0;
        }
        & > * {
          margin-bottom: 32px;
          @include media($w1024) {
            margin-bottom: 30px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        &--homepage {
          margin: 0 0 30px;
        }
      }
    }
    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
