.opinions {
  h3 {
    margin: 0 0 45px;
    @include media($w1175) {
      margin: 0 0 40px;
    }
    @include media($w1024) {
      margin: 0 0 30px;
    }
  }
  &__list {
    margin: 0 0 45px;
    @include media($w1175) {
      margin: 0 0 40px;
    }
    @include media($w1024) {
      margin: 0 0 30px;
    }
    li {
      & + li {
        margin: 25px 0 0;
      }
      .info {
        border-radius: 6px;
        background-color: $gray-03;
        font-size: 16px;
        line-height: 40px;
        margin: 0 0 16px;
        padding: 0 16px;
        @include media($w768) {
          font-size: 14px;
          line-height: 22px;
          padding: 10px 15px;
        }
        time {
          font-size: 14px;
          color: $paragraph;
          margin: 0 0 0 20px;
          display: inline-block;
          @include media($w768) {
            width: 100%;
            margin: 0;
          }
        }
      }
      .comment {
        .avatar {
          width: 65px;
          float: left;
          @include media($w768) {
            width: 60px;
          }
          @include media($w560) {
            width: 50px;
          }
          img {
            border-radius: 50%;
            width: 100%;
          }
        }
        .text {
          width: calc(100% - 100px);
          margin: 0 0 15px;
          float: right;
          @include media($w768) {
            width: calc(100% - 85px);
          }
          @include media($w560) {
            width: calc(100% - 70px);
          }
          p {
            font-size: 14px;
          }
        }
        & > a {
          font-size: 16px;
          color: $blue-03;
          float: right;
          @include media($w768) {
            font-size: 14px;
          }
          &:hover {
            color: $blue-02;
          }
        }
      }
    }
  }
  form {
    @include media($w768) {
      border-bottom: 1px solid darken($gray-03,5%);
      padding: 0 0 6px;
    }
    h5 {
      margin: 0 0 32px;
      @include media($w1024) {
        margin: 0 0 30px;
      }
      @include media($w768) {
        margin: 0 0 25px;
      }
    }
    .row {
      @include main;
      & + .row {
        margin: 16px 0 0;
        @include media($w768) {
          margin: 15px 0 0;
        }
      }
      label {
        width: 135px;
        font-size: 16px;
        line-height: 48px;
        color: $paragraph;
        float: left;
        @include media($w1175) {
          line-height: 44px;
        }
        @include media($w768) {
          width: 100%;
          font-size: 14px;
          line-height: 14px;
          margin: 0 0 14px;
        }
        span {
          color: $red;
        }
      }
      input,
      textarea,
      button {
        appearance: none;
      }
      input,
      textarea {
        border: 1px solid darken($gray-03,30%);
        border-radius: 11px;
        width: 320px;
        height: 48px;
        font-size: 16px;
        color: $gray-01;
        padding: 0 25px;
        float: left;
        @include media($w1175) {
          width: 300px;
          height: 44px;
        }
        @include media($w1024) {
          width: 280px;
        }
        @include media($w768) {
          width: 100%;
          font-size: 14px;
          line-height: 40px;
          padding: 0 20px;
        }
      }
      textarea {
        width: 100%;
        height: 127px;
        padding: 15px 25px;
        resize: none;
        @include media($w1175) {
          height: 115px;
        }
      }
      &--last {
        label {
          width: 100%;
        }
      }
    }
    .btn {
      margin: 25px 0 20px;
      float: right;
    }
  }
}
