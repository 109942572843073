.header {
  width: 100%;
  height: 88px;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  @include media($w768) {
    height: 80px !important;
  }
  .container {
    height: 88px;
    @include media($w768) {
      height: 80px !important;
    }
    .logo {
      @include translateY;
      z-index: 25;
      top: calc(50% + 2px);
      float: left;
      @include media($w560) {
        top: 50%;
      }
      a {
        font-size: 26px;
        font-weight: 900;
        letter-spacing: -0.6px;
        color: $white;
        @include media($w1024) {
          font-size: 24px;
        }
        @include media($w768) {
          font-size: 22px;
        }
        @include media($w560) {
          font-size: 20px;
          letter-spacing: -0.4px;
        }
        span {
          color: $red;
        }
        img {
          width: 36px;
          vertical-align: middle;
          margin: 0 5px 0 0;
          display: inline-block;
          position: relative;
          bottom: 3px;
          @include media($w1024) {
            width: 32px;
            bottom: 2px;
          }
          @include media($w768) {
            width: 27px;
          }
          @include media($w560) {
            width: 24px;
          }
        }
      }
    }
    .languages {
      @include translateY;
      z-index: 50;
      float: right;
      .current {
        border-radius: 20px;
        background-color: $blue-04;
        width: 200px;
        height: 40px;
        font-size: 15px;
        font-weight: 500;
        line-height: 38px;
        color: $gray-03;
        cursor: pointer;
        padding: 0 24px;
        position: relative;
        @include media($w768) {
          width: 170px;
          font-size: 14px;
          padding: 0 20px;
        }
        @include media($w560) {
          width: 130px;
        }
        &:after {
          @include transition;
          background-image: url(../images/dropdown--icon.svg);
          background-size: cover;
          width: 10px;
          height: 7px;
          display: block;
          position: absolute;
          z-index: 25;
          top: 16px;
          right: 18px;
        }
        span {
          @include media($w560) {
            display: none;
          }
        }
      }
      ul {
        &.dropdown {
          border-radius: 20px;
          background-color: $blue-04;
          padding: 10px 0;
          display: none;
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          li {
            a {
              font-size: 15px;
              line-height: 30px;
              color: $gray-03;
              padding: 0 24px;
              display: block;
              @include media($w768) {
                font-size: 14px;
                line-height: 26px;
                padding: 0 20px;
              }
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
      &.show {
        .current {
          &:after {
            transform: rotate(180deg);
            top: 15px;
          }
        }
      }
    }
    form {
      @include translateY;
      z-index: 25;
      float: left;
      @include media($w620) {
        @include translateReset;
        width: 100%;
        margin: 18px 0 0;
      }
      input,
      button {
        appearance: none;
      }
      input {
        border: 0;
        border-radius: 20px;
        width: 235px;
        height: 40px;
        font-size: 16px;
        color: $gray-01;
        padding: 0 25px;
        @include media($w1024) {
          width: 200px;
          padding: 0 20px;
        }
        @include media($w768) {
          width: 170px;
          height: 36px;
        }
        @include media($w620) {
          width: 100%;
        }
      }
      button {
        border: 0;
        border-radius: 20px;
      	background-color: transparent;
        width: 40px;
        height: 40px;
        text-align: center;
      	cursor: pointer;
        margin: 0 0 0 -40px;
        padding: 0 20px 0 0;
        position: relative;
        top: 5px;
        @include media($w768) {
          width: 36px;
          height: 36px;
          margin: 0 0 0 -36px;
          padding: 0 20px 0 0;
        }
        img {
          width: 20px;
          margin: 0;
          display: inline-block;
          @include media($w768) {
            width: 16px;
            margin: 0 0 3px;
          }
        }
      }
    }
  }
  &--subpage {
    background-image: url(../images/search--bg.jpg);
    background-position: 5% 50%;
    margin: 0 0 40px;
    position: relative;
    top: auto;
    left: auto;
    @include media($w1024) {
      margin: 0 0 30px;
    }
    @include media($w620) {
      height: auto !important;
      padding: 20px 0;
    }
    .container {
      @include media($w620) {
        height: auto !important;
      }
      .logo {
        margin: 0 50px 0 0;
        @include media($w1175) {
          margin: 0 40px 0 0;
        }
        @include media($w1024) {
          margin: 0 30px 0 0;
        }
        @include media($w620) {
          @include translateReset;
          margin: 5px 0 0;
        }
      }
      .languages {
        @include media($w620) {
          @include translateReset;
        }
      }
    }
  }
}
