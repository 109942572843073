.open-programs {
  margin: 0 0 50px;
  @include media($w1175) {
    margin: 0 0 40px;
  }
  @include media($w1024) {
    margin: 0 0 30px;
  }
  h3 {
    margin: 0 0 45px;
    @include media($w1175) {
      margin: 0 0 40px;
    }
    @include media($w1024) {
      margin: 0 0 30px;
    }
  }
  &__item {
    border-bottom: 1px solid darken($gray-03,4%);
    padding: 20px 0;
    &:first-child {
      padding: 0 0 20px;
    }
    .soft {
      width: 115px;
      float: left;
      @include media($w768) {
        width: 140px;
      }
      @include media($w560) {
        width: 100%;
        margin: 0 0 20px;
      }
      .inner {
        @include translateY;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        @include media($w1024) {
          font-size: 18px;
        }
        @include media($w768) {
          font-size: 16px;
        }
        @include media($w560) {
          @include translateReset;
          text-align: left;
        }
        img {
          margin: 0 auto 20px;
          @include media($w560) {
            margin: 0 0 15px;
          }
        }
      }
    }
    .list {
      width: calc(100% - 115px);
      padding: 0 0 0 50px;
      float: right;
      @include media($w768) {
        width: calc(100% - 140px);
        padding: 0;
      }
      @include media($w560) {
        width: 100%;
      }
      ul {
        li {
          & + li {
            margin: 18px 0 0;
            @include media($w768) {
              margin: 15px 0 0;
            }
            @include media($w560) {
              margin: 12px 0 0;
            }
          }
          a {
            font-size: 18px;
            font-weight: 500;
            color: $blue-03;
            @include media($w1024) {
              font-size: 16px;
            }
            @include media($w768) {
              font-size: 14px;
            }
            .icon {
              width: 50px;
              vertical-align: middle;
              display: inline-block;
              position: relative;
              bottom: 1px;
              @include media($w560) {
                width: 40px;
              }
            }
            &:hover {
              color: $blue-02;
            }
          }
        }
      }
    }
  }
}
