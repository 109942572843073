* {
	@include box-sizing;
	font-family: 'Roboto', sans-serif;
	&:before,
	&:after {
		@include box-sizing;
		content: '';
		display: none;
	}
}

::-moz-selection {
	background: $gray-01;
	color: $white;
}

::selection {
	background: $gray-01;
	color: $white;
}

body {
	background-color: $gray-03;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 100%;
	color: $blue-01;
	margin: 0;
	padding: 0;
}

strong, b {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

a {
	@include transition;
	text-decoration: none;
	color: $blue-01;
	&:hover {
		color: $blue-03;
	}
}

ul {
	@include main;
	li {
		@include main;
		position: relative;
	}
}

img {
	margin: 0;
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

p {
	font-size: 16px;
	line-height: 23px;
	color: $paragraph;
	@include media($w1024) {
		font-size: 14px;
		line-height: 20px;
	}
}

h1 {
	font-size: 48px;
	font-weight: 700;
	line-height: 48px;
	@include media($w1175) {
		font-size: 42px;
		line-height: 42px;
	}
	@include media($w1024) {
		font-size: 36px;
		line-height: 36px;
	}
	@include media($w768) {
		font-size: 30px;
		line-height: 30px;
	}
	@include media($w560) {
		font-size: 26px;
		line-height: 26px;
	}
	& > span {
		font-weight: 400;
	}
}

h3 {
	font-size: 28px;
	line-height: 110%;
	position: relative;
	@include media($w1024) {
		font-size: 24px !important;
	}
	@include media($w768) {
		font-size: 22px !important;
	}
	@include media($w560) {
		font-size: 20px !important;
	}
	&:after {
		background-color: $red;
		width: 32px;
		height: 2px;
		display: block;
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
	}
	&.type {
		font-size: 40px;
		font-weight: 700;
		line-height: 110%;
		color: $blue-03;
		&:after {
			display: none;
		}
		&--small {
			font-size: 28px;
			@include media($w1024) {
				font-size: 24px !important;
			}
		}
	}
}

h5 {
	font-size: 20px;
	line-height: 28px;
	@include media($w1024) {
		font-size: 18px;
		line-height: 26px;
	}
	@include media($w768) {
		font-size: 16px;
		line-height: 24px;
	}
	&.small {
		font-size: 14px;
		line-height: 14px;
	}
}

h6 {
	font-size: 16px;
	line-height: 24px;
}

/* --- [ global styles ] --- */

.wrapper {
	@include main;
	overflow: hidden;
}

.container {
	max-width: 1175px;
	margin: 0 auto;
	padding: 0 25px;
	position: relative;
}

button {
	@include transition;
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.btn {
	@include transition;
	border: 2px solid $blue-03;
	border-radius: 5px;
	background-color: transparent;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 100%;
	text-decoration: none;
	color: $blue-03;
	cursor: pointer;
	padding: 8px 14px 9px;
	display: inline-block;
	@include media($w560) {
		font-size: 14px;
	}
	&:hover {
		border-color: $blue-02;
		color: $blue-02;
	}
	&--send {
		border: 0;
		background-color: $blue-03;
		padding: 16px 24px;
		color: $white;
		@include media($w1024) {
			padding: 13px 22px;
		}
		@include media($w768) {
			padding: 12px 20px;
		}
		&:hover {
			background-color: $blue-02;
			color: $white;
		}
	}
}

.clearfix {
	&:after {
		clear: both;
		display: block;
	}
}

.page {
	@include media($w560) {
    padding: 10px 0 0;
  }
  h1 {
    margin: 0 0 40px;
		@include media($w1024) {
			margin: 0 0 30px;
		}
  }
}
