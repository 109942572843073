.side-social {
  padding: 0 0 0 15px;
  @include media($w1024) {
		padding: 0 0 0 5px;
	}
  h6 {
    line-height: 39px;
    color: $paragraph;
    float: left;
  }
  ul {
    width: auto;
    float: right;
    li {
      width: auto;
      & + li {
        margin: 0 0 0 12px;
        @include media($w1024) {
      		margin: 0 0 0 10px;
      	}
      }
      a {
        border: 1px solid darken($gray-03,8%);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        float: left;
        @include media($w1024) {
      		width: 39px;
          height: 39px;
      	}
        img {
          @include translate;
          height: 18px;
        }
        &:hover {
          border-color: darken($gray-03,15%);
        }
      }
    }
  }
}
