.side-buttons {
  border-radius: 11px;
  background-color: $gray-03;
  padding: 25px 16px;
  h5 {
    margin: 0 0 25px;
  }
  .sb {
    &__list {
      margin: 0 0 15px;
      li {
        width: auto;
        margin: 0 8px 8px 0;
        a {
          border-radius: 11px;
          background-color: $white;
          font-size: 16px;
          font-weight: 500;
          line-height: 47px;
          color: $blue-03;
          padding: 0 25px 1px;
          float: left;
          @include media($w1024) {
            line-height: 40px;
            padding: 0 20px 1px;
          }
          @include media($w560) {
            font-size: 14px;
            line-height: 36px;
          }
          &:hover {
            color: $blue-02;
          }
        }
      }
    }
  }
  .btn {
    margin: 2px 0 0;
  }
}
