@mixin main {
	width: 100%;
	position: relative;
	float: left;
}

@mixin transition {
	transition: 0.4s ease;
}

@mixin box-sizing {
	box-sizing: border-box;
}

@mixin translate {
	transform: translate(-50%,-50%);
	position: relative;
	top: 50%;
	left: 50%;
}

@mixin translateX {
	transform: translateX(-50%);
	position: relative;
	left: 50%;
}

@mixin translateY {
	transform: translateY(-50%);
	position: relative;
	top: 50%;
}

@mixin translateReset {
	transform: translate(0);
	position: relative;
	top: auto;
	left: auto;
}

// ** Media queries mixin ** //

@mixin media($media) {
  @media only screen and #{$media} {
    @content;
  }
}
