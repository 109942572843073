.description {
  margin: 0 0 55px;
  @include media($w1175) {
		margin: 0 0 40px;
	}
  @include media($w1024) {
		margin: 0 0 30px;
	}
  h3 {
    margin: 0 0 45px;
    @include media($w1175) {
      margin: 0 0 40px;
    }
    @include media($w1024) {
      margin: 0 0 30px;
    }
  }
  h4, h5, h6 {
    margin: 0 0 15px;
    strong {
      font-weight: 700;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
  h5 {
    font-size: 16px;
    line-height: 20px;
  }
  h6 {
    font-size: 14px;
    line-height: 18px;
  }
  p {
    margin: 0 0 25px;
  }
  *:last-child {
    margin: 0;
  }
}
