.date-table {
  ul {
    li {
      & + li {
        margin: 18px 0 0;
        @include media($w1024) {
          margin: 12px 0 0;
        }
      }
      .label {
        width: 145px;
        font-size: 16px;
        line-height: 24px;
        color: $paragraph;
        float: left;
        @include media($w1175) {
          width: 120px;
        }
        @include media($w1024) {
          width: 100%;
          margin: 0 0 5px;
        }
      }
      .value {
        font-size: 20px;
        line-height: 24px;
        vertical-align: middle;
        float: left;
        @include media($w1175) {
          font-size: 16px;
        }
        @include media($w1024) {
          width: 100%;
        }
        img {
          vertical-align: middle;
          display: inline-block;
          position: relative;
          bottom: 1px;
          & + img {
            margin: 0 0 0 4px;
          }
        }
        a {
          font-weight: 500;
          color: $blue-03;
          &:hover {
            color: $blue-02;
          }
        }
        .rating {
          border: 0;
          width: auto;
          margin: 0 15px 0 0;
          padding: 0;
          @include media($w560) {
            width: 100%;
            margin: 0 0 5px;
          }
          li {
            background-size: cover;
            width: 20px;
            height: 20px;
            & + li {
              margin: 0 0 0 5px;
            }
            &.full {
              background-image: url(../images/rate--icon-full.svg);
            }
            &.empty {
              background-image: url(../images/rate--icon-empty.svg);
            }
          }
        }
        span {
          font-size: 16px;
          color: $paragraph;
          @include media($w1175) {
            font-size: 15px;
          }
          @include media($w768) {
            font-size: 14px;
          }
          @include media($w560) {
            display: block;
          }
        }
      }
    }
  }
}
