.extension {
  border: 1px solid darken($gray-03,8%);
  border-radius: 11px;
  margin: 0 0 45px;
  padding: 20px 32px 32px 32px;
  @include media($w1024) {
    padding: 20px 30px 30px 30px;
  }
  @include media($w768) {
    padding: 15px 25px 25px 25px;
  }
  &__icon {
    border-radius: 14px;
    width: 128px;
    height: 144px;
    margin: 12px 0 0;
    box-shadow: 0 4px 16px 0 rgba(47,43,86,0.15);
    position: relative;
    float: left;
    @include media($w1175) {
      width: 100px;
      height: 115px;
    }
    @include media($w560) {
      display: none;
    }
    img {
      @include translate;
      width: 40%;
    }
  }
  .date-table {
    width: calc(100% - 155px);
    padding: 10px 0 0;
    float: right;
    @include media($w1175) {
      width: calc(100% - 130px);
    }
    @include media($w560) {
      width: 100%;
    }
    ul {
      border-top: 1px solid darken($gray-03,8%);
      margin: 15px 0 0;
      padding: 25px 0 0;
    }
  }
}
