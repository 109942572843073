.daily {
  margin: 0 0 50px;
  @include media($w1175) {
    margin: 0 0 40px;
  }
  @include media($w1024) {
    margin: 0 0 30px;
  }
  & > h3 {
    margin: 0 0 40px;
    @include media($w1024) {
      margin: 0 0 30px;
    }
  }
  &__item {
    margin: 0 0 20px;
    @include media($w768) {
      margin: 0 0 15px;
    }
    .icon {
      border-radius: 7px;
      width: 64px;
      height: 72px;
      margin: 6px 0 0;
      box-shadow: 0 2px 8px 0 rgba(47,43,86,0.15);
      position: relative;
      float: left;
      @include media($w560) {
        display: none;
      }
      img {
        @include translate;
        width: 40%;
      }
    }
    .text {
      width: calc(100% - 95px);
      padding: 5px 0 0;
      float: right;
      @include media($w1024) {
        padding: 15px 0 0;
      }
      @include media($w768) {
        padding: 17px 0 0;
      }
      @include media($w560) {
        width: 100%;
        padding: 4px 0 0;
      }
      h3 {
        margin: 0 0 2px;
      }
    }
  }
  p {
    margin: 0 0 25px;
    a {
      color: $blue-03;
    }
  }
  &--small {
    border-radius: 11px;
    border: 1px solid darken($gray-03,10%);
    padding: 25px 16px;
    & > h5 {
      margin: 0 0 15px;
    }
    .daily__item {
      margin: 0 0 10px;
      @include media($w768) {
        margin: 0 0 15px;
      }
      .icon {
        border-radius: 5px;
        width: 43px;
        height: 48px;
        margin: 5px 0 0;
        box-shadow: 0 1px 5px 0 rgba(47,43,86,0.15);
      }
      .text {
        width: calc(100% - 60px);
        padding: 3px 0 0;
        @include media($w768) {
          padding: 4px 0 0;
        }
        @include media($w560) {
          width: 100%;
          padding: 0;
        }
        h3 {
          margin: 0 0 4px;
        }
      }
    }
    p {
      font-size: 14px;
      margin: 0 0 15px;
    }
    .btn {
      margin: 2px 0 0;
    }
  }
}
