.programs {
  margin: 0 0 40px;
  @include media($w1024) {
		margin: 0 0 30px;
	}
  h3 {
    margin: 0 0 45px;
    @include media($w1175) {
  		margin: 0 0 40px;
  	}
    @include media($w1024) {
  		margin: 0 0 35px;
  	}
  }
  ul {
    li {
      & + li {
        margin: 18px 0 0;
        @include media($w768) {
      		margin: 15px 0 0;
      	}
      }
      a {
        font-size: 18px;
        font-weight: 500;
        color: $blue-03;
        @include media($w1024) {
      		font-size: 16px;
        }
        @include media($w560) {
      		font-size: 14px;
        }
        .icon {
          width: 50px;
          vertical-align: middle;
          display: inline-block;
          position: relative;
          bottom: 1px;
          @include media($w768) {
        		width: 40px;
          }
        }
        &:hover {
          color: $blue-02;
        }
      }
    }
  }
}
