.breadcrumbs {
  margin: 0 0 35px;
  @include media($w1024) {
		margin: 0 0 30px;
	}
  @include media($w560) {
    display: none;
  }
  ul {
    li {
      width: auto;
      & + li {
        margin: 0 0 0 8px;
      }
      a {
        border-radius: 6px;
        background-color: $gray-03;
        font-size: 14px;
        line-height: 28px;
        color: $blue-03;
        padding: 0 12px;
        float: left;
        &:hover {
          color: $blue-02;
        }
      }
      span {
        border: 2px solid darken($gray-03,5%);
        border-radius: 6px;
        font-size: 14px;
        line-height: 24px;
        color: $blue-02;
        padding: 0 12px;
        float: left;
      }
    }
  }
}
