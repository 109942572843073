.footer {
  font-size: 14px;
  line-height: 24px;
  padding: 45px 0 65px;
  @include media($w1024) {
    padding: 35px 0 50px;
  }
  @include media($w768) {
    padding: 30px 0;
  }
  &__copy {
    opacity: 0.4;
    margin: 0 0 0 15px;
    float: left;
    @include media($w560) {
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0 15px;
    }
  }
  &__menu {
    margin: 0 15px 0 0;
    float: right;
    @include media($w768) {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      float: left;
    }
    ul {
      width: auto;
      @include media($w560) {
        width: 100%;
        text-align: center;
      }
      li {
        width: auto;
        @include media($w560) {
          display: inline-block;
          float: none;
        }
        & + li {
          margin: 0 0 0 42px;
          @include media($w768) {
            margin: 0 0 0 20px;
          }
        }
        a {
          font-weight: 500;
        }
      }
    }
  }
}
