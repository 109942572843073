.alphabet {
  border-bottom: 1px solid darken($gray-03,5%);
  margin: 0 0 50px;
  padding: 13px 0 32px;
  @include media($w1175) {
    margin: 0 0 40px !important;
  }
  @include media($w1024) {
    margin: 0 0 30px !important;
    padding: 10px 0 30px;
  }
  h3 {
    margin: 0 0 50px;
    @include media($w1175) {
      margin: 0 0 40px;
    }
    @include media($w1024) {
      margin: 0 0 30px;
    }
  }
  ul {
    margin: 0 0 -16px;
    li {
      width: auto;
      margin: 0 16px 16px 0;
      @include media($w1024) {
        margin: 0 12px 12px 0;
      }
      @include media($w768) {
        margin: 0 8px 8px 0;
      }
      &:nth-child(14n+14) {
        margin: 0 0 16px;
        @include media($w1175) {
          margin: 0 16px 16px 0;
        }
        @include media($w1024) {
          margin: 0 12px 12px 0;
        }
        @include media($w768) {
          margin: 0 8px 8px 0;
        }
      }
      a {
        border-radius: 50%;
        background-color: $gray-03;
        width: 60px;
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        line-height: 58px;
        text-align: center;
        color: $blue-03;
        float: left;
        @include media($w1175) {
          width: 56px;
          height: 56px;
          font-size: 18px;
          line-height: 54px;
        }
        @include media($w1024) {
          width: 50px;
          height: 50px;
          font-size: 16px;
          line-height: 48px;
        }
        @include media($w768) {
          width: 44px;
          height: 44px;
          font-size: 14px;
          line-height: 42px;
        }
        @include media($w560) {
          width: 40px;
          height: 40px;
          line-height: 38px;
        }
        &:hover {
          color: $blue-02;
        }
      }
    }
  }
}
