.search {
  background-image: url(../images/search--bg.jpg);
  background-position: 5% 50%;
  color: $white;
  margin: 0 0 50px;
  padding: 155px 0 50px;
  @include media($w1175) {
    margin: 0 0 40px;
    padding: 140px 0 40px;
  }
  @include media($w1024) {
    margin: 0 0 30px;
    padding: 120px 0 35px;
  }
  @include media($w768) {
    padding: 100px 0 30px;
  }
  @include media($w560) {
    text-align: center;
    padding: 90px 0 30px;
  }
  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 110%;
    margin: 0 0 10px;
    @include media($w1175) {
      font-size: 30px;
    }
    @include media($w1024) {
      font-size: 28px;
    }
    @include media($w768) {
      font-size: 24px;
    }
    @include media($w560) {
      font-size: 22px;
    }
  }
  p {
    font-size: 18px;
    line-height: 110%;
    color: $white;
    opacity: 0.7;
    margin: 0 0 25px;
    @include media($w1024) {
      font-size: 16px;
      margin: 0 0 20px;
    }
    @include media($w768) {
      font-size: 14px;
      margin: 0 0 23px;
    }
    @include media($w560) {
      max-width: 250px;
      margin: 0 auto 23px;
    }
  }
  form {
    input,
    button {
      appearance: none;
    }
    input {
      border: 0;
      border-radius: 32px;
      width: 540px;
      height: 64px;
      font-size: 18px;
      color: $gray-01;
      padding: 0 25px;
      @include media($w1175) {
        width: 520px;
        height: 56px;
        font-size: 17px;
      }
      @include media($w1024) {
        width: 480px;
        height: 50px;
        font-size: 16px;
        padding: 0 20px;
      }
      @include media($w768) {
        width: 400px;
        height: 44px;
        font-size: 14px;
      }
      @include media($w560) {
        width: 100%;
      }
    }
    button {
      border: 0;
      border-radius: 32px;
    	background-color: transparent;
      width: 64px;
      height: 64px;
      text-align: center;
    	cursor: pointer;
      margin: 0 0 0 -64px;
      padding: 0 20px 0 0;
      position: relative;
      top: 5px;
      @include media($w1175) {
        width: 56px;
        height: 56px;
        margin: 0 0 0 -56px;
      }
      @include media($w1024) {
        width: 50px;
        height: 50px;
        margin: 0 0 0 -50px;
      }
      @include media($w1024) {
        width: 44px;
        height: 44px;
        margin: 0 0 0 -44px;
      }
      img {
        width: 24px;
        display: inline-block;
        @include media($w1024) {
          width: 22px;
        }
        @include media($w768) {
          width: 20px;
        }
      }
    }
    ::-webkit-input-placeholder {
    	color: $input !important;
    	opacity: 0.7;
    }
    :-moz-placeholder {
    	color: $input !important;
    	opacity: 0.7;
    }
    ::-moz-placeholder {
    	color: $input !important;
    	opacity: 0.7;
    }
    :-ms-input-placeholder {
    	color: $input !important;
    	opacity: 0.7;
    }
  }
}
