.program {
  border: 1px solid darken($gray-03,8%);
  border-radius: 11px;
  margin: 0 0 45px;
  padding: 32px 32px 25px 0;
  @include media($w1175) {
    margin: 0 0 40px;
  }
  @include media($w1024) {
    margin: 0 0 30px;
    padding: 30px 30px 25px 0;
  }
  @include media($w560) {
    margin: 0 0 30px;
    padding: 25px;
  }
  &__logotype {
    width: 200px;
    float: left;
    @include media($w1175) {
      width: 170px;
    }
    @include media($w560) {
      width: 100%;
    }
    img {
      max-width: 80%;
      margin: 0 auto;
      @include media($w560) {
        margin: 0 0 14px;
      }
    }
  }
  .date-table {
    width: calc(100% - 200px);
    float: right;
    @include media($w1175) {
      width: calc(100% - 170px);
    }
    @include media($w560) {
      width: 100%;
    }
    ul {
      border-bottom: 1px solid darken($gray-03,8%);
      margin: 0 0 20px;
      padding: 0 0 25px;
      @include media($w560) {
        margin: 0 0 25px;
        padding: 0 0 20px;
      }
    }
  }
}
